<template>
  <div class="contactscomp">
    <div>
      <div class="start-table text-center mb-5 d-flex justify-content-between">
        <div class="contact-image">
          <img src="/img/get-started.12c669f1.svg" alt="" width="30" />
          <p>Contact History</p>
        </div>
        <!-- <b-dropdown
          variant="primary"
          :text="selectedListName"
          class="mr-4 small-drop-select"
          v-model="selectedListName"
        >
          <b-dropdown-item
            v-for="(list, i) in listSystems"
            :key="i"
            @click="onSelectDropdown(list)"
            >{{ list.listName }}
          </b-dropdown-item>
        </b-dropdown> -->

        <div class="cst-group-btn d-flex">
          <b-dropdown v-if="listSystems.length > 0" variant="primary" :text="selectedListName" class="small-drop-select"
            v-model="selectedListName">
            <b-dropdown-item v-for="(list, i) in listSystems" :key="i" @click.stop="onSelectDropdown(list)">
              {{ list.listName }}
            </b-dropdown-item>
          </b-dropdown>
          <button v-if="selectedListId" class="btn btn-primary" @click="hanldeDeleteModal()">
            Delete {{ selectedListName }}
          </button>
          <button class="btn btn-primary" @click="changeSelectedItem('create')">
            Create list
          </button>
        </div>
      </div>
      <b-modal ref="createListModel" no-close-on-backdrop centered hide-footer hide-header-close
        modal-class="create-list-modal">
        <template #modal-title>
          Create List
          <div class="enrich-head">
            <i class="fa fa-times" aria-hidden="true" @click="closeCreateListModel"></i>
          </div>
        </template>
        <div class="create-list-content">
          <label for="range-1" class="mr-2">Name</label>
          <b-form-input id="range-1" v-model="listName" type="text" placeholder="Enter name"></b-form-input>
        </div>

        <b-button class="create-list-btn crt-btn" @click="createList"
          :disabled="listName && listName != '' ? false : true">Create</b-button>
        <b-button variant="danger" class="create-list-btn dlt-btn" @click="closeCreateListModel">Cancel</b-button>
      </b-modal>
      <div class="row">
        <modal name="delete-modal" class="modaldata">
          <div class="enrich-head">
            <i class="fa fa-times" aria-hidden="true" @click="closeModal"></i>
          </div>
          <div class="enrichmodal newenrichmodal">
            <div>
              <h4 v-if="selectedIdToDelete" style="text-align: center;  ">
                <span class="text-color">Are you sure,</span><br />
                You want to delete the this record.
              </h4>

              <h4 v-else style="text-align: center;  ">
                <span class="text-color">Are you sure,</span><br />
                You want to delete the list ({{ selectedListName }})
              </h4>
            </div>

            <div class="action_button" style="text-align: center;">
              <button @click="closeModal" class="action_perform secondary-btn-outline">
                No
              </button>
              <div>
                <button v-if="selectedIdToDelete" @click="deleteContactFromList(selectedIdToDelete)"
                  class="action_perform secondary-btn-outline">
                  Yes
                </button>
                <button v-else @click="deleteListSystem" class="action_perform secondary-btn-outline">
                  Yes
                </button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </div>
    <div class="start-table bottom-area table-custom " v-if="isData">
      <div class="button-top ">
        <div v-if="dataToExport.length > 0" @click="downloadFile('excel')" class="btn btn-sm-primary mt-3 mb-1">
          Export to excel
        </div>
        <div v-if="dataToExport.length > 0" @click="downloadFile('csv')" class="btn btn-sm-primary mt-3 mb-1">
          Export to Csv
        </div>
      </div>

      <b-table responsive bordered hover primary-key="name" :fields="fields" :items="contactList"
        class="bg-white mw-container-table table-new scroll-new">
        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div>
              <span class="letter-cont">
                {{ getFirstLetters(data.item.name) }}
              </span>
            </div>

            <div v-if="data.item.linkedInUrl" class="linkedin-icon-cls">
              <a :href="data.item.linkedInUrl" target="_blank">{{
            data.item.name
          }}</a>
              <img class="d-block mt-1" width="13" src="@/assets/images/icons/linkedin-ico.svg" alt="Linkedin" />
            </div>

            <div v-if="!data.item.linkedInUrl">
              {{ data.item.name }}
            </div>
          </div>
        </template>

        <!-- New Template For Company Linkedin -->
        <template #cell(companyLinkedin)="data">
          <div v-if="data.item.premium && data.item.premium.url" class="linkedin-icon-cls">
            <a :href="data.item.premium.url" target="_blank">
              <img class="d-block mt-1" width="13" src="@/assets/images/icons/linkedin-ico.svg" alt="Linkedin" />
            </a>
          </div>
        </template>

        <template #cell(emails)="data">
          <div class="text-left">
            <div class="d-flex flex-column" v-if="data.item.emails && data.item.emails.length > 0">
              <span class="d-flex" v-for="(itemEmail, index) in data.item.emails" :key="index">
                <span class="d-flex" v-if="itemEmail && itemEmail.email">
                  {{ itemEmail.email }}
                  <span class="ml-1" v-if="itemEmail.status == 'Valid' ||
            itemEmail.status == 'CatchAllButFoundOnWeb'
            ">
                    <img src="@/assets/images/icons/valid-ico.svg" />
                  </span>
                  <span class="ml-1" v-if="itemEmail.status == 'CatchAll' ||
            itemEmail.status == 'riskey'
            ">
                    <img src="@/assets/images/icons/invalid-ico.svg" />
                  </span>
                  <span id="copy-wrapper-email" @click="copyText(itemEmail.email)" class="copy-button-email ms-3"
                    title="Copy">
                    <img src="@/assets/images/icons/copy-ico.svg" />
                  </span>
                  <b-tooltip placement="top" target="copy-wrapper-email">Copy</b-tooltip>
                </span>
              </span>
            </div>
          </div>
        </template>
        <template #cell(premium)="data">
          <div class="text-center number-add-new" v-if="data.item.premium">
            <span class="number-n" v-for="(element, index) in data.item.premium.specialtiesArr" :key="index">
              <b-badge pill="true" active="true" class="mx-1" :variant="getRandomBadge()">{{ element.value
                }}</b-badge></span>
          </div>
        </template>

        <template #cell(phoneNumber)="data">
          <div class="text-center">
            <b-button class="btn save-bt btn-secondary m-auto" @click="callPhoneApi(data)" v-if="data.item.linkedInUrl &&
            !data.item.showAdvanceSearch &&
            data.item.phones.length == 0
            ">
              Access Phone
            </b-button>

            <div class="change-number">
              <div v-if="data.item.phones &&
            data.item.phones.length > 0 &&
            !data.item.showAdvanceSearch
            ">
                <div class="change-secund">
                  <div class="number-add">
                    <img src="@/assets/images/icons/phone-ico.svg" />
                    <span class="number-n"> {{ data.item.phones[0] }}</span>
                  </div>

                  <div class="add-flex">
                    <span id="copy-wrapper" @click="copyText(data.item.phones[0])" class="copy-button ms-3"
                      title="Copy">
                      <img src="@/assets/images/icons/copy-ico.svg" />
                    </span>
                    <b-tooltip placement="top" target="copy-wrapper">Copy</b-tooltip>
                    <span v-if="data.item.phones && data.item.phones.length > 1" class="dropdown-butn-outline ">
                      <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none phone-class" no-caret>
                        <template #button-content>
                          <img src="@/assets/images/icons/plus-ico.svg" />
                        </template>
                        <span class="icon-drop">
                          <b-dropdown-item href="javascript:void(0)" class="text-dark"
                            v-for="(phoneObj, l) in data.item.phones" :key="l">
                            <div class="new-imag-s d-flex justify-content-between">
                              <img src="@/assets/images/icons/phone-ico.svg" />

                              <span class="number-n"> {{ phoneObj }}</span>

                              <span @click="copyText(phoneObj)" class="copy-button ms-3" title="Copy">
                                <img src="@/assets/images/icons/copy-ico.svg" />
                              </span>
                              <span @click="openWhatsApp(phoneObj)" v-if="phoneObj &&
            phoneObj.type &&
            phoneObj.type == 'mobile'
            " class="copy-button ms-1 ">
                                <img src="@/assets/images/icons/whatsapp-ico.svg" />
                              </span>
                            </div>
                          </b-dropdown-item>
                        </span>
                      </b-dropdown>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="data.item.showAdvanceSearch">
              <a :href="'javascript:void(0)'" @click="openSearchPage(data.item)">
                Try Advanced Search</a>
            </div>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="text-center">
            <button class="border-0 rounded-circle trash-icon" @click="hanldeDeleteModal(data.item.contactId)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </template>
        <template #cell(integration)="data">
          <div v-if="hubspotConnected" class="text-center">
            <button class="border-0 rounded-circle hubspot-icon" @click="saveContactTohubspot(data.item)">
              <img style="width: 24px" :src="require(`@/assets/images/icons/hubspot_logo.svg`)" alt="" />
            </button>
          </div>
        </template>
        <template #cell(createdAt)="data">
          <div class="text-center  number-add">
            <span class="number-n">
              {{ getformatDate(data.item.createdAt) }}</span>
          </div>
        </template>
      </b-table>
      <div class="pagination-box" v-if="contactList.length && contactList.length > 0">
        <span class="prev-btn-nav" v-bind:class="isPaginationDisable('prev') ? 'disabled' : ''"
          @click="pageClick('prev')" :disabled="isPaginationDisable('prev')">
          <span class="triangle-prev"></span>
        </span>

        <span class="number-of-page">
          {{ currentPage == 1 ? 1 : (currentPage - 1) * perPage + 1 }} -
          {{
            nextPageId && nextPageId != "" ? currentPage * perPage : totalCount
          }}
          of
          {{ totalCount }}
        </span>

        <span class="next-btn-nav" v-bind:class="isPaginationDisable('next') ? 'disabled' : ''"
          @click="pageClick('next')" :disabled="isPaginationDisable('next')">
          <span class="triangle-next"> </span>
        </span>
      </div>
    </div>
    <div class="no-records text-center" v-if="!isData">
      <span class="text-center">{{ noRecords }}</span>
    </div>
  </div>
</template>

<script>
import {
  DELETE_CONTACTS_LIST_API,
  GET_CONTACTS_LIST_API,
  GET_USER_LIST_API,
  FIND_PHONENUMBER_OF_USER,
  DELETE_LIST_API,
  GET_CONNECTIONS,
  SAVE_CONTACT_TO_HUBSPOT,
  CREATE_LIST_API,
  USER_EMAIL,
} from "../../../store/constant/actions.type";
import Vue from "vue";
import vSelect from "vue-select";
import moment from "moment";
Vue.component("v-select", vSelect);
import { loadingService } from "../../common/services/loading.service";
import exportFromJSON from "export-from-json";

export default {
  name: "contacts",
  data() {
    return {
      isData: false,
      selectedListName: "Select List",
      listName: "",
      selectedListId: null,
      allSelected: false,
      nextPageId: null,
      selectedIdToDelete: null,
      previousPageId: null,
      currentPage: 1,
      perPage: 10,
      totalCount: null,
      listSystems: [],
      noRecords: null,
      hubspotConnected: false,
      dataToExport: [],
      fields: [
        { key: "name", label: "Person", sortable: true },
        { key: "jobTitle", label: "Job Title", sortable: true },
        { key: "company", label: "Company", sortable: true },
        { key: "emails", label: "Email Address", sortable: false },
        { key: "phoneNumber", label: "Mobile Phone", sortable: false },
        { key: "integration", label: "Integration", sortable: false },
        { key: "location", label: "Location", sortable: false },
        // { key: "extractedRole", label: "Role", sortable: true },
        // { key: "extractedSeniority", label: "Seniority", sortable: true },
        { key: "companyLinkedin", label: 'Linkedin Company', sortable: false },
        { key: "premium.website", label: "Website", sortable: false },
        {
          key: "premium.employeesAmountInLinkedin",
          label: "Number Of Employees",
          sortable: false,
        },
        {
          key: "premium",
          label: "Specialties",
          sortable: false,
        },
        { key: "createdAt", label: "Date", sortable: true },
        { key: "action", label: "Action", sortable: true },
      ],
      contactList: [],
    };
  },

  mounted() {
    this.getUserListSystem();
    this.getConnections();
  },

  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
  },

  methods: {
    changeSelectedItem(val) {
      if (val === "create") {
        this.$refs["createListModel"].show();
      }
    },
    closeModal() {
      this.$modal.hide("delete-modal");
    },
    createList() {
      let payload = {
        apiId: localStorage.getItem("$s"),
        user: localStorage.getItem(USER_EMAIL),
        listName: this.listName,
      };
      loadingService.setloader(true);
      if (this.listName !== "") {
        this.$store
          .dispatch(CREATE_LIST_API, payload)
          .then((response) => {
            if (response) {
              this.selectedListName = response.listName;
              this.selectedListId = response.listId;
              this.listSystems.push(response);
              this.$refs["createListModel"].hide();
              this.listName = "";
              this.onSelectDropdown(response);
            }
            loadingService.setloader(false);
          })
          .catch((err) => {
            loadingService.setloader(false);
            throw err;
          });
      } else {
        loadingService.setloader(false);
      }
    },
    closeCreateListModel() {
      this.listName = "";
      this.$refs["createListModel"].hide();
    },
    getRandomBadge() {
      let badgeColor = [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
      ];
      let badgeNumber = Math.floor(Math.random() * (30 - 0 + 1)) + 0;
      return badgeColor[badgeNumber] ? badgeColor[badgeNumber] : "primary";
    },
    downloadFile(type) {
      const fileName = "download";
      let data = this.dataToExport;
      if (type == "excel") {
        const exportType = exportFromJSON.types.xls;
        exportFromJSON({ data, fileName, exportType });
      } else {
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      }
    },
    getformatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    onSelectDropdown(list) {
      this.selectedListName = list.listName;
      this.selectedListId = list.listId;
      this.getContactList(this.selectedListId, this.perPage, null, null);
      this.getContactListForExport(this.selectedListId);
    },
    getFirstLetters(str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word[0])
        .join("");

      return firstLetters;
    },
    isPaginationDisable(type) {
      if (type == "prev") {
        if (this.previousPageId && this.previousPageId != "") {
          if (this.currentPage == 1) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      } else {
        if (this.nextPageId && this.nextPageId != "") {
          return false;
        } else {
          return true;
        }
      }
    },

    pageClick(type) {
      if (type == "prev") {
        if (this.previousPageId && this.previousPageId != "") {
          this.currentPage = this.currentPage - 1;
          this.getContactList(
            this.selectedListId,
            this.perPage,
            this.previousPageId,
            "previous"
          );
        }
      } else {
        if (this.nextPageId && this.nextPageId != "") {
          this.currentPage = this.currentPage + 1;
          this.getContactList(
            this.selectedListId,
            this.perPage,
            this.nextPageId,
            null
          );
        }
      }
    },

    deleteContactFromList(contactId) {
      loadingService.setloader(true);
      let payload = {
        apiId: localStorage.getItem("$s"),
        listId: this.selectedListId,
        contactId: contactId,
      };
      this.$store
        .dispatch(DELETE_CONTACTS_LIST_API, payload)
        .then((response) => {
          if (response) {
            this.getContactList(this.selectedListId, this.perPage, null, null);
            this.getContactListForExport(this.selectedListId);
            this.$modal.hide("delete-modal");
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          loadingService.setloader(false);
          throw err;
        });
    },
    hanldeDeleteModal(id) {
      if (id) {
        this.selectedIdToDelete = id;
        this.$modal.show("delete-modal");
      } else {
        this.selectedIdToDelete = null;
        this.$modal.show("delete-modal");
      }
    },

    deleteListSystem() {
      loadingService.setloader(true);
      let payload = {
        apiId: localStorage.getItem("$s"),
        listId: this.selectedListId,
      };

      if (this.selectedListId) {
        this.$store
          .dispatch(DELETE_LIST_API, payload)
          .then((response) => {
            if (response) {
              this.contactList = [];
              this.getUserListSystem();
              this.getConnections();
              this.$modal.hide("delete-modal");
              this.isData = false;
            }
            loadingService.setloader(false);
          })
          .catch((err) => {
            loadingService.setloader(false);
            throw err;
          });
      }
    },

    getContactList(listId, limit, pageId, direction) {
      if (!pageId) this.currentPage = 1;
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONTACTS_LIST_API, {
          listId: listId,
          limit: limit,
          apiId: localStorage.getItem("$s"),
          pageId: pageId,
          direction: direction,
        })
        .then((response) => {
          if (response && response.contacts && response.contacts.length > 0) {
            this.contactList = response.contacts;
            this.nextPageId = response.nextPageId;
            this.previousPageId = response.previousPageId;
            if (response.count && response.count != "") {
              this.totalCount = response.count;
            }
            this.isData = true;
          } else {
            this.contactList = [];
            this.noRecords = "No Records Found!";
            this.isData = false;
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          loadingService.setloader(false);
          throw err;
        });
    },
    getContactListForExport(listId) {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONTACTS_LIST_API, {
          listId: listId,
          apiId: localStorage.getItem("$s"),
        })
        .then((response) => {
          if (response && response.contacts && response.contacts.length > 0) {
            this.dataToExport = [];
            response.contacts.forEach((element) => {
              let phoneString = "";
              let emailString = "";
              if (element.emails && element.emails.length > 0) {
                element.emails.forEach((mailObj) => {
                  if (mailObj && mailObj.email) {
                    if (emailString == "") {
                      emailString = mailObj.email;
                    } else {
                      emailString = `${emailString}  ${mailObj.email}`;
                    }
                  }
                });
              }
              if (element.phones && element.phones.length > 0) {
                element.phones.forEach((phoneObj) => {
                  if (phoneObj && phoneObj != "") {
                    if (emailString == "") {
                      phoneString = phoneObj;
                    } else {
                      phoneString = `${phoneString}  ${phoneObj}`;
                    }
                  }
                });
              }
              let obj = {
                name: element.name,
                jobTitle: element.jobTitle,
                company: element.company,
                emails: emailString,
                phones: phoneString,
                location: element.location,
                extractedRole: element.extractedRole,
                extractedSeniority: element.extractedSeniority,
                linkedInUrl: element.linkedInUrl,
                createdAt: element.createdAt,
              };
              this.dataToExport.push(obj);
            });
          } else {
            this.dataToExport = [];
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          loadingService.setloader(false);
          throw err;
        });
    },
    copyText(val) {
      this.$clipboard(val);
    },
    getUserListSystem() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_USER_LIST_API, {
          apiKey: localStorage.getItem("$s"),
        })
        .then((response) => {
          if (
            response &&
            response.listSystems &&
            response.listSystems.length > 0
          ) {
            this.listSystems = response.listSystems;
            this.selectedListName = response.listSystems[0].listName;
            this.selectedListId = response.listSystems[0].listId;
            this.getContactList(this.selectedListId, this.perPage, null, null);
            this.getContactListForExport(this.selectedListId);
            loadingService.setloader(false);
          }
        })
        .catch((err) => {
          if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
        });
    },
    openSearchPage(item) {
      var handle = window.open("advance-phone-search", "_blank");
      handle.window.parameters = JSON.stringify({
        firstName: item.firstName,
        lastName: item.lastName,
      });
    },

    callPhoneApi(data) {
      loadingService.setloader(true);
      this.$store
        .dispatch(FIND_PHONENUMBER_OF_USER, {
          linkedInUrl: data.item.linkedInUrl,
        })
        .then((response) => {
          this.$eventBus.$emit("callCreditMethod");
          if (response) {
            let searchRes = [];
            if (response.person && response.person.length > 0) {
              searchRes = [...searchRes, ...response.person];
            }
            if (
              response.person &&
              response.person.constructor.name == "Object"
            ) {
              searchRes = [...searchRes, ...[response.person]];
            }

            if (
              response.possiblePersons &&
              response.possiblePersons.length > 0
            ) {
              searchRes = [...searchRes, ...response.possiblePersons];
            }
            if (
              response.possiblePersons &&
              response.possiblePersons.constructor.name == "Object"
            ) {
              searchRes = [...searchRes, ...[response.possiblePersons]];
            }
            if (searchRes && searchRes.length > 0) {
              let phoneArray = [];
              searchRes.forEach((element) => {
                if (element && element.phones && element.phones.length > 0) {
                  element.phones.forEach((phn) => {
                    if (phn && phn.displayInternational)
                      phoneArray.push(phn.displayInternational);
                  });
                }
              });
              if (phoneArray && phoneArray.length > 0) {
                const updatedRow = {
                  ...this.contactList[data.index],
                  showAdvanceSearch: false,
                  phones: phoneArray,
                };
                this.contactList.splice(data.index, 1, updatedRow);
              }
            } else {
              const updatedRow = {
                ...this.contactList[data.index],
                showAdvanceSearch: true,
                phones: [],
              };
              this.contactList.splice(data.index, 1, updatedRow);
            }
          } else {
            const updatedRow = {
              ...this.contactList[data.index],
              showAdvanceSearch: true,
              phones: [],
            };
            this.contactList.splice(data.index, 1, updatedRow);
          }
          loadingService.setloader(false);
        })
        .catch((err) => {
          if (err.data.message == "not allow from France") {
            loadingService.setloader(false);
            this.flashMessage.info({
              title: "Not found",
              message:
                "Vous n’êtes pas autorisé à faire cette recherche. Si vous pensez que c’est une erreur, contactez-nous.",
            });
          } else if (err && err.data && err.data.code) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
          const updatedRow = {
            ...this.contactList[data.index],
            showAdvanceSearch: true,
            phones: [],
          };
          this.contactList.splice(data.index, 1, updatedRow);
        });
    },
    getConnections() {
      loadingService.setloader(true);
      this.$store
        .dispatch(GET_CONNECTIONS)
        .then((data) => {
          loadingService.setloader(false);
          if (data && data.data && data.data.length > 0) {
            this.hubspotConnected = false;
            let hubIndex = data.data.findIndex((x) => x.key == "hubspot");
            if (hubIndex != -1) {
              this.hubspotConnected = true;
            }
          } else {
            this.hubspotConnected = false;
          }
        })
        .catch((err) => {
          loadingService.setloader(false);
          this.hubspotConnected = false;
          if (err && err.data && err.data.message) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error Message",
              message: err.data.message,
            });
          }
        });
    },
    saveContactTohubspot(contact) {
      let contactArray = { inputs: [] };
      let obj = {
        properties: {
          company: contact.company,
          email:
            contact.emails && contact.emails.length > 0
              ? contact.emails[0].email
              : "",
          // firstname: contact.first.split(" ")[0],
          // lastname: contact.name.split(' ').slice(1).join(' '),
          jobtitle: contact.jobTitle,

          linkedinurl: contact.linkedInUrl,
          phone:
            contact.phones && contact.phones.length > 0
              ? contact.phones[0]
              : "",
        },
      };
      if (contact.firstName == "" || contact.lastName == "") {
        obj.properties.firstname = contact.name.split(" ")[0];
        obj.properties.lastname = contact.name
          .split(" ")
          .slice(1)
          .join(" ");
      } else if (contact.firstName != "" && contact.lastName != "") {
        obj.properties.firstname = contact.firstName;
        obj.properties.lastname = contact.lastName;
      }
      if (
        (contact.premium != null && contact.premium?.website != "") ||
        contact?.premium?.website != null
      ) {
        obj.properties.website = contact.premium.website;
      } else if (
        contact?.premium == "" ||
        contact?.premium == null ||
        contact?.premium?.website == "" ||
        contact?.premium?.website == null
      ) {
        obj.properties.website = "";
      }
      if (
        (contact.premium != null && contact?.premium?.specialties != "") ||
        contact?.premium?.specialties != null
      ) {
        obj.properties.specialties = contact.premium.specialties;
      } else if (
        contact?.premium == "" ||
        contact?.premium == null ||
        contact?.premium?.specialties == "" ||
        contact?.premium?.specialties == null
      ) {
        obj.properties.specialties = "";
      }
      contactArray.inputs.push(obj);
      loadingService.setloader(true);
      this.$store
        .dispatch(SAVE_CONTACT_TO_HUBSPOT, contactArray)
        .then(() => {
          loadingService.setloader(false);
          this.flashMessage.success({
            title: "Success",
            message: "Contacts saved",
          });
        })
        .catch((err) => {
          if (err && err.data && err.data.status) {
            loadingService.setloader(false);
            this.flashMessage.error({
              title: "Error",
              message: err.data.message
                ? err.data.message
                : err.data.description
                  ? err.data.description
                  : "Error Occurs",
            });
          }
        });
    },
  },
};
</script>

<style>
.pagination-box>span {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #b1b7c2;
  font-family: "Montserrat", sans-serif;
}

.pagination-box .disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.start-table.bottom-area.table-custom {
  padding-top: 0;
}

.start-table.bottom-area.table-custom th {
  font-size: 14px;
  line-height: 1.2;
  vertical-align: middle;
}

.modaldata ::v-deep .vm--modal {
  padding: 30px !important;
}

.enrich-head i {
  color: #003172;
  color: #fff;
}

.enrich-head {
  text-align: right;
  background: #3f6fcc;
  padding-right: 9px;
}

.enrich-head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.action_button {
  width: 100%;
  padding: 6px 15px;
}

.newenrichmodal .action_button {
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.enrichmodal.newenrichmodal {
  position: static;
  transform: none;
  margin-top: 50px;
}

.enrichmodal {
  position: absolute;
  width: 100%;
  top: 56%;
  transform: translateY(-50%);
}

.pagination-box {
  display: flex;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 13px;
  padding: 5px 10px 5px;
  background: rgb(255 255 255);
  flex-wrap: wrap;
  width: 190px;
  justify-content: space-between;
}

.copy-button-email img {
  margin-left: 10px;
}

.triangle-prev {
  display: inline-block;
  border-right: 10px solid rgb(151 152 151);
  border-top: 8px solid rgb(0 0 0 / 0%);
  border-bottom: 8px solid rgb(0 0 0 / 0%);
}

.triangle-next {
  display: inline-block;
  border-left: 10px solid rgb(151 152 151);
  border-top: 8px solid rgb(0 0 0 / 0%);
  border-bottom: 8px solid rgb(0 0 0 / 0%);
}

.triangle-down {
  display: inline-block;
  margin-left: 12px;
  border-left: 6px solid transparent;
  border-top: 7px solid rgb(151 152 151);
  border-right: 6px solid transparent;
}

/* .start-table span.letter-cont {
  background: rgb(255 122 89);
  padding: 16px 16px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  margin-right: 11px;
} */
.start-table span.letter-cont {
  background: rgb(255 122 89);
  padding: 10px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  margin-right: 11px;
  height: 50px;
  display: flex;
  text-align: center;
  width: 50px;
  align-items: center;
  justify-content: center;
}

.phone-number a {
  border: 1px solid rgb(221 226 230);
  border-radius: 11px;
  background: rgb(247 248 250);
  display: inline-block;
  color: #000;
}

.phone-number {
  padding: 5px 54px 0px 0px;
}

.phone-number img {
  padding: 3px 5px 3px 10px;
  background: rgb(255 255 255);
  width: 34px;
  border-radius: 11px 0px 0px 13px;
}

.email-number {
  padding: 2px 16px 0px 0px;
}

.email-number a {
  border: 1px solid rgb(221 226 230);
  border-radius: 11px;
  background: rgb(247 248 250);
  column-gap: 9px;
  /* display: flex; */
  /* align-items: center; */
  display: inline-block;
  color: #000;
}

button.btn.save-bt {
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #a5adba;
  margin-right: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.email-number .success-img {
  padding: 3px 10px;
  margin-left: 0;
  background: rgb(33 184 158);
  width: 34px;
  border-radius: 11px 0px 0px 11px;
}

.success-img img {
  max-width: 14px;
}

.number-class {
  padding: 0px 8px;
  display: inline-block;
}

.table-bordered td,
.table-bordered th {
  border: unset;
  border-bottom: 1px solid rgb(222 226 230);
  vertical-align: middle;
  font-size: 14px;
}

.start-table.bottom-area {
  box-shadow: 0 0 4px #00000024;
  padding: 20px 10px;
  border-radius: 10px;
}

.linkedin-icon-cls a {
  font-size: 14px;
}

.linkedin-icon-cls {
  font-size: 14px;
}

.search-bar .input-group input {
  height: 72px;
  background: rgb(255 255 255);
  color: var(--vs-selected-color);
  border-top: 1px solid rgb(223 225 230);
}

.trash-icon {
  font-size: 26px;
}

.trash-icon:hover {
  background: #dadada;
}

.trash-icon {
  font-size: 26px;
  height: 40px;
  width: 40px;
  color: rgb(197 9 15);
  background: none;
  transition: all 0.3s ease;
}

.scroll-new.table-responsive table {
  width: 1600px;
  min-height: 660px;
}

.number-add {
  width: 157px;
}

.number-add-new {
  width: 300px;
}

.dropdown-menu.show {
  transform: translate3d(0px, 32px, 0px) !important;
}

.change-secund {
  display: flex;
  align-items: center;
}

.phone-class {
  padding: 3px 4px 3px 4px;
}

.add-flex {
  display: flex;
  align-items: center;
}

.btn.btn-sm-primary {
  background: rgb(37 137 255);
  border: 1px solid rgb(236 236 236);
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: rgb(255 255 255);
  margin-right: 52px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.button-top {
  display: flex;
  justify-content: end;
  gap: 52px;
}

.hubspot-icon {
  font-size: 7px;
  height: 40px;
  width: 40px;
  color: rgb(197 9 15);
  background: rgb(238 238 238);
  transition: all 0.3s ease;
}

.hubspot-icon:hover {
  background-color: #fff;
}

.cst-group-btn {
  gap: 20px;
}

.contactscomp .start-table {
  margin-top: 0px;
  overflow-y: inherit;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.1);
}

.contactscomp .start-table.bottom-area.table-custom tr th {
  background: #3f8aff !important;
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
  width: auto;
}

.contactscomp .button-top {
  gap: 30px;
  align-items: center;
  padding: 20px;
}

.contactscomp .button-top .btn.btn-sm-primary {
  margin: 0 !important;
  padding: 10px 20px;
}

.contactscomp .mw-container-table .b-table a,
.mw-container-table .b-table td {
  padding: 10px;
}

.contactscomp .mw-container-table .b-table .text-center,
.contactscomp .mw-container-table .b-table .number-add {
  text-align: left !important;
}

.contactscomp .scroll-new.table-responsive table {
  width: 3250px;
  /* min-height: 660px; */
  min-height: auto;
  table-layout: fixed;
}

.start-table.bottom-area.table-custom {
  min-height: 660px;
}

.contactscomp .mw-container-table .b-table {
  min-width: 3250px;
}

/* .contactscomp .start-table.bottom-area.table-custom tr th:nth-child(1) {
  width: 300px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(2) {
  width: 245px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(3) {
  width: 300px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(4) {
  width: 400px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(5) {
  width: 145px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(6) {
  width: 120px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(7) {
  width: 400px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(8) {
  width: 150px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(9) {
  width: 200px;
}

.contactscomp .start-table.bottom-area.table-custom tr th:nth-child(10) {
  width: 200px;
} */

.contactscomp .start-table.bottom-area.table-custom tr th:last-child {
  width: 95px;
}

.contactscomp .start-table.bottom-area.table-custom tr td:first-child a {
  padding: 0;
}

.contactscomp .start-table {
  margin-bottom: 30px !important;
}

.contact-image {
  display: flex;
  align-items: center;
}

.contact-image p {
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}

.enrichmodal.newenrichmodal {
  height: 243px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-list-modal .modal-header {
  background-color: #3f6fcc;
}

.create-list-modal .modal-header .modal-title {
  color: #fff;
}

button.btn.create-list-btn {
  margin-top: 20px;
  margin-right: 15px;
}

button.btn.create-list-btn {
  background: #3f6fcc;
  border: 0;
}

button.btn.create-list-btn.dlt-btn {
  border: 1px solid #3f6fcc;
  background: transparent;
  color: #3f6fcc;
}

.contactscomp .start-table .small-drop-select .btn {
  border-radius: 2px;
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 400;
}

.create-list-modal .enrich-head {
  width: 30px;
  right: 10px;
  top: 6px;
  left: inherit;
  cursor: pointer;
}

.dropdown-toggle:after {
  vertical-align: top;
  height: 20px;
  width: 20px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  border: 0;
}

@media screen and (max-width: 1880px) {
  .main-content {
    box-sizing: border-box;
    overflow-x: hidden !important;
  }
}
</style>
